import React from 'react'
import PropTypes from 'prop-types'

import matchSorter from 'match-sorter'
import MuiAutocomplete from '@material-ui/lab/Autocomplete'
import { compose, borders, display, positions, shadows, sizing, spacing, typography } from '@material-ui/system'

import TextField from './TextField'
import { styled } from '@material-ui/core'

const AutocompleteComponent = ({ options, value, onChange, ...rest }) => {
  const filterOptions = (options, { inputValue }) => matchSorter(options, inputValue, { keys: ['label', 'value'] })

  return (
    <Autocomplete
      popupIcon={null}
      value={value === '' ? null : value}
      onChange={(_, newValue) => onChange(newValue)}
      options={options}
      filterOptions={filterOptions}
      getOptionLabel={option => option.label || ''}
      getOptionSelected={(option, value) => value.value === option.value}
      noOptionsText='Nenhuma opção'
      renderInput={params => <TextField {...params} {...rest} autoComplete='off' />}
    />
  )
}

const Autocomplete = styled(MuiAutocomplete)(compose(borders, display, positions, shadows, sizing, spacing, typography))

AutocompleteComponent.defaultProps = {
  value: ''
}

AutocompleteComponent.propTypes = {
  value: PropTypes.any,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    })
  ).isRequired
}

export default AutocompleteComponent
