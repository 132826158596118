import React from 'react'
import PropTypes from 'prop-types'
import { styled } from '@material-ui/core/styles'
import {
  compose,
  borders,
  display,
  flexbox,
  positions,
  shadows,
  sizing,
  palette,
  spacing,
  typography
} from '@material-ui/system'

import { LinearProgress, CircularProgress } from '@material-ui/core'

import Column from '../Grid/Column'

const LoaderComponent = props => <Loader {...props} />

const Loader = styled(({ type, ...props }) => {
  if (type === 'linear') {
    return <LinearProgress {...props} />
  }
  if (type === 'circular') {
    return <CircularProgress {...props} />
  }
  if (type === 'fullscreen') {
    return (
      <Column
        position='fixed'
        top={0}
        right={0}
        bottom={0}
        left={0}
        alignItems='center'
        justifyContent='center'
        bgcolor='rgba(0,0,0,.5)'
        style={{ zIndex: 9999 }}
      >
        <CircularProgress {...props} />
      </Column>
    )
  }
})(compose(borders, display, flexbox, positions, shadows, sizing, spacing, typography, palette))

LoaderComponent.defaultProps = {
  type: 'linear'
}

LoaderComponent.propTypes = {
  type: PropTypes.oneOf(['linear', 'circular', 'fullscreen'])
}

export default LoaderComponent
