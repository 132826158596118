import React from 'react'
import PropTypes from 'prop-types'

import ButtonGroup from '@material-ui/core/ButtonGroup'

import Button from '../Button'
import Text from '../Text'

const ButtonGroupComponent = ({ options, value, onChange, buttonProps, textProps, ...rest }) => (
  <ButtonGroup {...rest}>
    {options.map(option => (
      <Button
        disableElevation
        key={option.value}
        onClick={() => onChange(option.value)}
        px={5}
        variant={option.value === value ? 'contained' : 'outlined'}
        {...buttonProps}
      >
        <Text
          color={option.value === value ? 'primary.contrastText' : 'neutral.n400'}
          fontSize='14px'
          style={{ textTransform: 'capitalize' }}
          {...textProps}
        >
          {option.label}
        </Text>
      </Button>
    ))}
  </ButtonGroup>
)

ButtonGroupComponent.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string
    })
  ).isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  buttonProps: PropTypes.object,
  textProps: PropTypes.object
}

export default ButtonGroupComponent
