import React from 'react'
import PropTypes from 'prop-types'

import { makeStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import TextField from '@material-ui/core/TextField'
import InputAdornment from '@material-ui/core/InputAdornment'
import MaterialTreeView from '@material-ui/lab/TreeView'

import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import SearchIcon from '@material-ui/icons/Search'

const useStyles = makeStyles(theme => ({
  icon: {
    color: theme.palette.neutral.n400
  }
}))

const TreeView = ({ children, onSearchChange, ...rest }) => {
  const classes = useStyles()
  return (
    <Box width='100%'>
      <TextField
        fullWidth
        onChange={onSearchChange}
        InputProps={{
          startAdornment: (
            <InputAdornment>
              <SearchIcon classes={{ root: classes.icon }} />
            </InputAdornment>
          )
        }}
        {...rest}
      />
      <Box width='100%' marginTop='15px'>
        <MaterialTreeView defaultCollapseIcon={<ExpandMoreIcon />} defaultExpandIcon={<ChevronRightIcon />}>
          {children}
        </MaterialTreeView>
      </Box>
    </Box>
  )
}

TreeView.propTypes = {
  children: PropTypes.node.isRequired,
  onSearchChange: PropTypes.func
}

TreeView.defaultProps = {
  onSearchChange: () => null
}

export default TreeView
