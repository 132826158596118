import React from 'react'
import PropTypes from 'prop-types'
import Checkbox from '@material-ui/core/Checkbox'
import TextField from '@material-ui/core/TextField'
import MAutocomplete from '@material-ui/lab/Autocomplete'
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank'
import CheckBoxIcon from '@material-ui/icons/CheckBox'
import { styled } from '@material-ui/core/styles'
import {
  compose,
  borders,
  display,
  flexbox,
  positions,
  shadows,
  sizing,
  spacing,
  typography
} from '@material-ui/system'

const icon = <CheckBoxOutlineBlankIcon fontSize='small' />
const checkedIcon = <CheckBoxIcon fontSize='small' />

const MultiSelect = ({ label, options, value = [], variant, onChange, placeholder, error, ...props }) => {
  const id = props.id || props.name || 'multiselect'

  return (
    <Autocomplete
      multiple
      disableCloseOnSelect
      id={id}
      value={value}
      options={options}
      onChange={(_, newValue) => onChange(newValue)}
      getOptionLabel={option => option.label}
      getOptionSelected={(option, value) => value.value === option.value}
      renderInput={params => (
        <TextField
          variant={variant}
          label={label}
          placeholder={placeholder}
          {...params}
          id={id}
          {...(!!error && {
            error: true,
            helperText: error
          })}
        />
      )}
      renderOption={(option, { selected }) => (
        <React.Fragment>
          <Checkbox icon={icon} checkedIcon={checkedIcon} style={{ marginRight: 8 }} checked={selected} />
          {option.label}
        </React.Fragment>
      )}
      {...props}
    />
  )
}

const Autocomplete = styled(MAutocomplete)(
  compose(borders, display, flexbox, positions, shadows, sizing, spacing, typography)
)

MultiSelect.defaultProps = {
  variant: 'standard'
}

MultiSelect.propTypes = {
  label: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.any,
  onChange: PropTypes.func,
  variant: PropTypes.string,
  error: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.any,
      label: PropTypes.string
    })
  )
}

export default MultiSelect
