import React from 'react'
import PropTypes from 'prop-types'
import CKEditor from 'ckeditor4-react'

import config from './config'

const ControlledTextEditor = ({ value, onChange }) => {
  const handleChange = event => {
    onChange(event.editor.getData())
  }

  return <CKEditor config={config} data={value} onChange={handleChange} />
}

ControlledTextEditor.defaultProps = {
  value: ''
}

ControlledTextEditor.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired
}

export default ControlledTextEditor
