import React from 'react'
import PropTypes from 'prop-types'
import { InputLabel, MenuItem, FormHelperText, FormControl, Select, Typography } from '@material-ui/core'
import { compose, display, positions, shadows, sizing, spacing, typography } from '@material-ui/system'
import { styled } from '@material-ui/core/styles'

const DropdownComponent = ({ label, options, value, onChange, error, allowEmpty, ...props }) => {
  const ref = props.id || props.name || 'select'

  return (
    <DropdownWrapper error={!!error} {...props}>
      <InputLabel>{label}</InputLabel>
      <Select id={ref} value={value || ''} onChange={onChange}>
        {!!label && <MenuItem disabled>{label}</MenuItem>}
        {!!allowEmpty && (
          <MenuItem id={`${ref}_empty`} value=''>
            <em>{allowEmpty}</em>
          </MenuItem>
        )}
        {options.map((option, index) => (
          <MenuItem key={option.value} value={option.value} disabled={option.disabled} id={`${ref}_${index}`}>
            <Typography noWrap>{option.label}</Typography>
          </MenuItem>
        ))}
      </Select>
      {!!error && <FormHelperText>{error}</FormHelperText>}
    </DropdownWrapper>
  )
}

const DropdownWrapper = styled(FormControl)(compose(display, positions, shadows, sizing, spacing, typography))

DropdownComponent.propTypes = {
  label: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.bool]),
      disabled: PropTypes.bool
    })
  ),
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.bool]),
  onChange: PropTypes.func,
  error: PropTypes.string,
  allowEmpty: PropTypes.string
}

export default DropdownComponent
