import React from 'react'
import PropTypes from 'prop-types'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'

import Row from '../Grid/Row'
import Text from '../Text/Text'

const ItemsPerPageDropdown = ({ rowsPerPage, setRowsPerPage, options, ...props }) => (
  <Row alignItems='center' {...props}>
    <Text fontSize={14} color='black' mr={1}>
      Itens por página
    </Text>
    <Select value={rowsPerPage} onChange={({ target }) => setRowsPerPage(+target.value)}>
      {options.map(option => (
        <MenuItem key={option} value={option}>
          {option}
        </MenuItem>
      ))}
    </Select>
  </Row>
)

ItemsPerPageDropdown.defaultProps = {
  rowsPerPage: 10,
  options: [10, 20, 30]
}

ItemsPerPageDropdown.propTypes = {
  rowsPerPage: PropTypes.number,
  setRowsPerPage: PropTypes.func,
  options: PropTypes.arrayOf(PropTypes.number)
}

export default ItemsPerPageDropdown
