import React from 'react'
import PropTypes from 'prop-types'
import Pagination from '@material-ui/lab/Pagination'
import TablePagination from '@material-ui/core/TablePagination'
import IconButton from '@material-ui/core/IconButton'
import FirstPageIcon from '@material-ui/icons/FirstPage'
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft'
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight'
import LastPageIcon from '@material-ui/icons/LastPage'
import { makeStyles } from '@material-ui/core/styles'

import Row from '../Grid/Row'

import ItemsPerPageDropdown from './ItemsPerPageDropdown'

const useStyles = makeStyles({
  root: {
    border: 'none'
  }
})

const PaginationComponent = ({
  count,
  page,
  rowsPerPage,
  onChange,
  setRowsPerPage,
  type,
  itemsPerPageOptions,
  ...props
}) => {
  const classes = useStyles()

  const TablePaginationActions = () => (
    <Row>
      <IconButton onClick={() => onChange(1)} disabled={page === 1} aria-label='first page'>
        <FirstPageIcon />
      </IconButton>
      <IconButton onClick={() => onChange(page - 1)} disabled={page === 1} aria-label='previous page'>
        <KeyboardArrowLeft />
      </IconButton>
      <IconButton
        onClick={() => onChange(page + 1)}
        disabled={page >= Math.ceil(count / rowsPerPage)}
        aria-label='next page'
      >
        <KeyboardArrowRight />
      </IconButton>
      <IconButton
        onClick={() => onChange(Math.max(0, Math.ceil(count / rowsPerPage)))}
        disabled={page >= Math.ceil(count / rowsPerPage)}
        aria-label='last page'
      >
        <LastPageIcon />
      </IconButton>
    </Row>
  )

  if (type === 'basic') {
    return (
      <Row justifyContent='flex-end' alignItems='center' {...props}>
        <ItemsPerPageDropdown rowsPerPage={rowsPerPage} setRowsPerPage={setRowsPerPage} options={itemsPerPageOptions} />
        <Pagination
          page={page}
          count={Math.ceil(count / rowsPerPage)}
          shape='rounded'
          color='primary'
          showFirstButton
          showLastButton
          onChange={(_, newPage) => onChange(newPage)}
        />
      </Row>
    )
  }

  return (
    <Row justifyContent='flex-end' {...props}>
      <TablePagination
        component='div'
        colSpan={3}
        page={page - 1}
        count={count}
        className={classes.root}
        rowsPerPage={rowsPerPage}
        rowsPerPageOptions={itemsPerPageOptions}
        onChangeRowsPerPage={event => setRowsPerPage(+event.target.value)}
        onChangePage={() => {}}
        labelDisplayedRows={({ from, to, count }) => `${from}-${to} de ${count}`}
        ActionsComponent={TablePaginationActions}
        labelRowsPerPage='Itens por página'
      />
    </Row>
  )
}

PaginationComponent.defaultProps = {
  rowsPerPage: 10,
  type: 'table',
  itemsPerPageOptions: [10, 20, 30]
}

PaginationComponent.propTypes = {
  count: PropTypes.number,
  page: PropTypes.number,
  onChange: PropTypes.func,
  rowsPerPage: PropTypes.number,
  setRowsPerPage: PropTypes.func,
  itemsPerPageOptions: PropTypes.arrayOf(PropTypes.number),
  type: PropTypes.oneOf(['table', 'basic'])
}

export default PaginationComponent
