import React, { useState, forwardRef } from 'react'
import { bool, func, oneOf, string, oneOfType, element } from 'prop-types'
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Slide,
  useTheme,
  makeStyles
} from '@material-ui/core'
import { Error, CheckCircle } from '@material-ui/icons'

import { Column, Button } from '../'

const useStyles = makeStyles(theme => ({
  errorIcon: {
    fontSize: 68,
    color: theme.palette.error.main
  },
  successIcon: {
    fontSize: 68,
    color: theme.palette.secondary.main
  },
  title: {
    textAlign: 'center'
  }
}))

const Transition = forwardRef((props, ref) => <Slide direction='up' ref={ref} {...props} />)

const AlertComponent = ({
  description,
  handleClose,
  isOpen,
  onCancel,
  onConfirm,
  disabled,
  title,
  type,
  zIndex,
  confirmLabel = 'SIM',
  cancelLabel = 'NÃO'
}) => {
  const classes = useStyles()

  const [isLoading, setIsLoading] = useState(false)

  const handleConfirm = async () => {
    setIsLoading(true)
    try {
      await onConfirm()
      handleClose()
    } catch (err) {
      console.log(err)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <Dialog
      open={isOpen}
      TransitionComponent={Transition}
      aria-labelledby='alert-dialog-slide-title'
      aria-describedby='alert-dialog-slide-description'
      disableBackdropClick
      disableEscapeKeyDown
      style={{ zIndex: zIndex || 1301 }}
    >
      <Column p={4} minWidth={500} alignItems='center'>
        {['error', 'confirm', 'error-confirmation'].includes(type) ? (
          <Error classes={{ root: classes.errorIcon }} />
        ) : (
          <CheckCircle classes={{ root: classes.successIcon }} />
        )}
        <DialogTitle classes={{ root: classes.title }} id='alert-dialog-slide-title'>
          {title}
        </DialogTitle>
        {description && (
          <DialogContent>
            {typeof description === 'string' ? (
              <DialogContentText align='center' id='alert-dialog-slide-description'>
                {description}
              </DialogContentText>
            ) : (
              description
            )}
          </DialogContent>
        )}
        <DialogActions>
          {['error', 'success'].includes(type) && (
            <Button
              id='confirm_alert'
              variant='contained'
              onClick={handleConfirm}
              color='secondary'
              minWidth={128}
              isLoading={isLoading}
            >
              OK
            </Button>
          )}

          {type === 'confirm' && (
            <>
              <Button
                id='confirm_alert'
                variant='contained'
                onClick={handleConfirm}
                color='secondary'
                minWidth={128}
                isLoading={isLoading}
              >
                {confirmLabel}
              </Button>
              <Button
                id='cancel_alert'
                disabled={isLoading}
                variant='contained'
                minWidth={128}
                color='default'
                onClick={() => {
                  onCancel()
                  handleClose()
                }}
              >
                {cancelLabel}
              </Button>
            </>
          )}

          {type === 'success-confirm' && (
            <>
              <Button
                id='cancel_alert'
                disabled={isLoading}
                variant='contained'
                minWidth={128}
                color='default'
                onClick={() => {
                  onCancel()
                  handleClose()
                }}
              >
                {cancelLabel}
              </Button>
              <Button
                id='confirm_alert'
                variant='contained'
                onClick={handleConfirm}
                color='secondary'
                minWidth={128}
                isLoading={isLoading}
              >
                {confirmLabel}
              </Button>
            </>
          )}

          {type === 'error-confirmation' && (
            <>
              <Button
                id='cancel_alert'
                disabled={isLoading}
                variant='contained'
                minWidth={128}
                color='default'
                onClick={() => {
                  onCancel()
                  handleClose()
                }}
              >
                {cancelLabel}
              </Button>
              <Button
                id='confirm_alert'
                variant='contained'
                disabled={disabled}
                onClick={handleConfirm}
                color='secondary'
                minWidth={128}
                isLoading={isLoading}
              >
                {confirmLabel}
              </Button>
            </>
          )}
        </DialogActions>
      </Column>
    </Dialog>
  )
}

AlertComponent.defaultProps = {
  onCancel: () => {},
  onConfirm: () => {},
  type: 'success'
}

AlertComponent.propTypes = {
  description: oneOfType([string, element]),
  isOpen: bool,
  onCancel: func,
  onClose: func,
  onConfirm: func,
  disabled: bool,
  title: string,
  confirmLabel: string,
  cancelLabel: string,
  type: oneOf(['success', 'error', 'confirm', 'success-confirm', 'error-confirmation'])
}

export default AlertComponent
