import React, { useState } from 'react'
import PropTypes from 'prop-types'
import matchSorter from 'match-sorter'
import clsx from 'clsx'

import Autocomplete from '@material-ui/lab/Autocomplete'
import { Box, CircularProgress, makeStyles } from '@material-ui/core'

import TextField from './TextField'

const useStyles = makeStyles(theme => ({
  root: {
    paddingRight: '0 !important'
  },
  icon: {
    color: props => (props.isActive ? theme.palette.secondary.main : theme.palette.neutral.n400)
  },
  spacing: {
    marginRight: theme.spacing(1)
  },
  loading: {
    color: 'inherit'
  }
}))

const AsyncAutocomplete = ({
  helperText,
  icon: Icon,
  inputValue,
  label,
  loading,
  onChange,
  onInputChange,
  options,
  value,
  freeSolo,
  ...rest
}) => {
  const classes = useStyles({ isActive: value?.value })
  const [open, setOpen] = useState(false)

  const filterOptions = (options, { inputValue }) => matchSorter(options, inputValue, { keys: ['label', 'value'] })

  return (
    <Autocomplete
      className={classes.root}
      filterOptions={filterOptions}
      getOptionSelected={(option, value) => option.value === value.value}
      getOptionLabel={option => option.label || ''}
      inputValue={inputValue}
      onInputChange={(_, newValue) => onInputChange(newValue)}
      onChange={(_, newValue) => onChange(newValue)}
      popupIcon={null}
      open={open && !!inputValue}
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      options={options}
      loading={loading}
      loadingText='Carregando...'
      noOptionsText='Nenhuma opção'
      value={value || ''}
      freeSolo={freeSolo}
      renderInput={params => (
        <TextField
          {...params}
          {...rest}
          helperText={helperText}
          InputProps={{
            ...params.InputProps,
            className: classes.root,
            ...(Icon && {
              startAdornment: (
                <>
                  <Icon className={clsx([classes.icon, classes.spacing])} />
                </>
              )
            }),
            ...(loading && {
              endAdornment: (
                <>
                  <Box className={classes.spacing}>
                    <CircularProgress className={classes.loading} size={20} />
                  </Box>
                </>
              )
            })
          }}
          label={label}
        />
      )}
    />
  )
}

AsyncAutocomplete.propTypes = {
  helperText: PropTypes.string,
  icon: PropTypes.element,
  inputValue: PropTypes.string.isRequired,
  label: PropTypes.string,
  loading: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  onInputChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    })
  ).isRequired,
  value: PropTypes.any,
  ...TextField.propTypes
}

export default AsyncAutocomplete
