import React, { forwardRef } from 'react'
import PropTypes from 'prop-types'
import { styled } from '@material-ui/core/styles'
import { compose, display, positions, sizing, spacing, typography } from '@material-ui/system'

import { TextField, InputAdornment } from '@material-ui/core'

const TextFieldComponent = forwardRef(({ error, icon, ...props }, ref) => {
  const id = props.id || props.name || 'input'

  const fieldProps = {
    id,
    ...props,
    ...(!!error && {
      error: true,
      helperText: error
    }),
    ...(!!icon && {
      InputProps: {
        startAdornment: <InputAdornment position='start'>{icon}</InputAdornment>
      }
    })
  }

  return <StyledTextField {...fieldProps} inputRef={ref} />
})

const StyledTextField = styled(TextField)(compose(display, positions, sizing, spacing, typography))

TextFieldComponent.propTypes = {
  error: PropTypes.string,
  icon: PropTypes.any
}

export default TextFieldComponent
