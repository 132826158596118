import React, { createContext, useContext, useState, useRef } from 'react'
import PropTypes from 'prop-types'
import { Alert } from '../components'

export function AlertProps() {
  return <div />
}

AlertProps.propTypes = {
  title: PropTypes.string,
  description: PropTypes.any,
  onConfirm: PropTypes.func,
  onCancel: PropTypes.func,
  type: PropTypes.oneOf(['success', 'error', 'confirm'])
}

AlertProps.defaultProps = {
  type: 'success',
  onConfirm: () => {},
  onCancel: () => {}
}

const AlertContext = createContext()

export const AlertProvider = props => {
  const [current, setCurrent] = useState({ isOpen: false })
  const [auxAlert, setAuxAlert] = useState({ isOpen: false })

  const ref = useRef(null)

  const alert = data => {
    if (ref?.current) {
      return setAuxAlert({ ...data, isOpen: true })
    }

    ref.current = true
    setCurrent({ ...data, isOpen: true })
  }

  const handleClose = () => {
    ref.current = false
    setCurrent(prev => ({ ...prev, isOpen: false }))
  }

  const handleCloseAux = () => {
    setAuxAlert(prev => ({ ...prev, isOpen: false }))
  }

  return (
    <>
      <AlertContext.Provider {...props} value={{ alert }} />
      <Alert {...current} handleClose={handleClose} />
      <Alert {...auxAlert} handleClose={handleCloseAux} zIndex={1302} />
    </>
  )
}

export const useAlert = () => {
  const context = useContext(AlertContext)

  if (context === undefined) {
    throw new Error('useAlert must be used within a AlertContext')
  }

  return context
}
