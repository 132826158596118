import React, { useState, useEffect, Fragment } from 'react'
import { arrayOf, bool, element, func, node, shape, string, object } from 'prop-types'
import clsx from 'clsx'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import { ExitToApp, ExpandLess, ExpandMore, Menu, ChevronLeft, ChevronRight } from '@material-ui/icons'
import {
  CssBaseline,
  Drawer,
  AppBar,
  Toolbar,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Divider,
  Typography,
  IconButton,
  Collapse,
  Box,
  Avatar,
  Popover,
  Button,
  Link,
  Tooltip
} from '@material-ui/core'

const drawerWidth = 240

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex'
  },
  appBar: {
    background: theme.palette.linearGradient.main,
    color: theme.palette.primary.contrastText,
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    })
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  menuButton: {
    marginRight: 36
  },
  hide: {
    display: 'none'
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap'
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    overflowX: 'hidden',
    width: 0,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(7) + 1
    }
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3)
  },
  nestedItem: {
    paddingLeft: theme.spacing(1)
  },
  username: {
    fontWeight: 600
  },
  avatar: {
    marginRight: theme.spacing(1),
    padding: 0,
    color: '#68bfac',
    backgroundColor: 'white',
    fontSize: '20px',
    fontWeight: 700
  },
  popover: {
    boxShadow: '0 3px 6px 0 rgba(0, 0, 0, 0.16)',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(3),
    minWidth: 250
  },
  popoverAvatar: {
    width: 64,
    height: 64,
    marginBottom: theme.spacing(1),
    fontSize: '20px',
    fontWeight: 700
  },
  popoverUsername: {
    fontSize: 14,
    fontWeight: 500
  },
  popoverRole: {
    color: theme.palette.neutral['n400'],
    fontSize: 12,
    marginBottom: theme.spacing(3)
  },
  popoverButton: {
    minWidth: 160,
    textTransform: 'unset',
    color: theme.palette.neutral['n400'],
    fontWeight: 500,
    marginTop: theme.spacing(2)
  },
  popoverLinkContainer: {
    width: '100%',
    marginBottom: theme.spacing(1)
  },
  popoverLink: {
    color: theme.palette.neutral['n400'],
    fontWeight: 400
  }
}))

const DrawerComponent = ({
  children,
  onChangePassword,
  onLogout,
  renderOpened,
  sideBarItems,
  title,
  user,
  terms,
  hasTooltip
}) => {
  const classes = useStyles()
  const theme = useTheme()

  const [open, setOpen] = useState(renderOpened)
  const [anchorEl, setAnchorEl] = useState(null)

  const handleDrawerOpen = () => {
    setOpen(true)
  }

  const handleDrawerClose = () => {
    setOpen(false)
  }

  const handleClickAvatar = event => {
    setAnchorEl(event.currentTarget)
  }

  const handleClosePopover = () => {
    setAnchorEl(null)
  }

  const { avatar_url, name, lastname, role } = user

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        position='fixed'
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open
        })}
      >
        <Toolbar>
          <IconButton
            color='inherit'
            aria-label='open drawer'
            onClick={handleDrawerOpen}
            edge='start'
            className={clsx(classes.menuButton, {
              [classes.hide]: open
            })}
          >
            <Menu />
          </IconButton>
          <Typography variant='h6' noWrap>
            {title}
          </Typography>
          <Box display='flex' alignItems='center' ml='auto'>
            <Box mr={2} textAlign='right'>
              <Typography classes={{ root: classes.username }}>{`${name} ${lastname}`}</Typography>
              <Typography>{role.name}</Typography>
            </Box>
            <Avatar alt={name} src={avatar_url} onClick={handleClickAvatar} classes={{ root: classes.avatar }}>
              {name[0].toUpperCase()}
              {lastname[0].toUpperCase()}
            </Avatar>
            <IconButton color='inherit' onClick={onLogout}>
              <ExitToApp />
            </IconButton>
          </Box>
          <Popover
            classes={{ paper: classes.popover }}
            open={!!anchorEl}
            anchorEl={anchorEl}
            onClose={handleClosePopover}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
          >
            <Avatar alt={name} src={avatar_url} classes={{ root: classes.popoverAvatar }}>
              {name[0].toUpperCase()}
              {lastname[0].toUpperCase()}
            </Avatar>
            <Typography classes={{ root: classes.popoverUsername }}>{`${name} ${lastname}`}</Typography>
            <Typography classes={{ root: classes.popoverRole }}>{role.name}</Typography>
            {terms &&
              terms.map(term => (
                <Box textAlign='left' classes={{ root: classes.popoverLinkContainer }}>
                  <Link
                    href={term.link}
                    rel='noopener noreferrer'
                    target='_blank'
                    classes={{ root: classes.popoverLink }}
                  >
                    {term.name}
                  </Link>
                </Box>
              ))}
            <Button
              classes={{ root: classes.popoverButton }}
              variant='outlined'
              color='default'
              onClick={onChangePassword}
            >
              Alterar Senha
            </Button>
          </Popover>
        </Toolbar>
      </AppBar>
      <Drawer
        variant='permanent'
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open
          })
        }}
      >
        <div className={classes.toolbar}>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'rtl' ? <ChevronRight /> : <ChevronLeft />}
          </IconButton>
        </div>
        <Divider />
        <List>
          {sideBarItems.map(sideBarItem =>
            sideBarItem.subItems?.length > 0 ? (
              <NestedList
                key={sideBarItem.text}
                hasTooltip={hasTooltip}
                item={sideBarItem}
                drawerOpen={open}
                handleDrawerOpen={handleDrawerOpen}
              />
            ) : (
              <Tooltip title={!open && hasTooltip ? sideBarItem.text : ''} placement='right'>
                <ListItem
                  id={`menu_${sideBarItem.text}`}
                  key={sideBarItem.text}
                  button
                  component={sideBarItem.component}
                  to={sideBarItem.path}
                >
                  <ListItemIcon>{sideBarItem.icon}</ListItemIcon>
                  <ListItemText primary={sideBarItem.text} />
                </ListItem>
              </Tooltip>
            )
          )}
        </List>
      </Drawer>
      <main className={classes.content}>
        <div className={classes.toolbar} />
        {children}
      </main>
    </div>
  )
}

DrawerComponent.defaultProps = {
  onChangePassword: () => {},
  onLogout: () => {},
  renderOpened: false,
  hasTooltip: false,
  user: {}
}

DrawerComponent.propTypes = {
  children: node,
  onChangePassword: func,
  onLogout: func,
  renderOpened: bool,
  hasTooltip: bool,
  sideBarItems: arrayOf(
    shape({
      text: string,
      icon: element,
      path: string,
      component: object,
      subItems: arrayOf(
        shape({
          text: string,
          icon: element,
          path: string,
          component: object
        })
      )
    })
  ),
  title: string,
  user: shape({
    avatar: string,
    name: string,
    role: string
  })
}

export default DrawerComponent

const NestedList = ({ item, drawerOpen, handleDrawerOpen, hasTooltip }) => {
  const classes = useStyles()

  const [open, setOpen] = useState(false)

  const handleClick = () => {
    setOpen(!open)
    if (!drawerOpen) {
      handleDrawerOpen()
    }
  }

  useEffect(() => {
    if (!drawerOpen && open) {
      setOpen(false)
    }
  }, [drawerOpen])

  return (
    <Fragment>
      <Tooltip title={!drawerOpen && hasTooltip ? item.text : ''} placement='right'>
        <ListItem id={`menu_${item.text}`} button onClick={handleClick}>
          <ListItemIcon>{item.icon}</ListItemIcon>
          <ListItemText primary={item.text} />
          {open ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
      </Tooltip>
      <Collapse in={open} timeout='auto' unmountOnExit>
        <div className={classes.nestedItem}>
          {item.subItems.map(subItem =>
            subItem.subItems?.length > 0 ? (
              <NestedList
                key={subItem.text}
                item={subItem}
                drawerOpen={drawerOpen}
                handleDrawerOpen={handleDrawerOpen}
              />
            ) : (
              <ListItem
                id={`menu_${item.text}_${subItem.text}`}
                key={subItem.text}
                button
                component={subItem.component}
                to={subItem.path}
              >
                <ListItemIcon>{subItem.icon}</ListItemIcon>
                <ListItemText primary={subItem.text} />
              </ListItem>
            )
          )}
        </div>
      </Collapse>
    </Fragment>
  )
}
