import React from 'react'
import { styled } from '@material-ui/core/styles'
import {
  compose,
  borders,
  display,
  flexbox,
  positions,
  shadows,
  sizing,
  spacing,
  typography
} from '@material-ui/system'

import { Fab, IconButton } from '@material-ui/core'

const IconButtonComponent = styled(({ color, ...props }) =>
  color === 'transparent' ? <IconButton {...props} /> : <Fab color={color} {...props} />
)(compose(borders, display, flexbox, positions, shadows, sizing, spacing, typography))

export default IconButtonComponent
