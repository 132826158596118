import React, { useState, useEffect, forwardRef } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'

import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import Slide from '@material-ui/core/Slide'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'

import BackIcon from '@material-ui/icons/ArrowBack'

const useStyles = makeStyles(theme => ({
  root: {
    [theme.breakpoints.up('md')]: {
      left: '125px !important'
    },
    [theme.breakpoints.up('lg')]: {
      left: '250px !important'
    },
    [theme.breakpoints.up('xl')]: {
      left: '500px !important'
    }
  },
  content: {
    padding: theme.spacing(3, 5)
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(3)
  },
  title: {
    paddingLeft: theme.spacing(),
    fontSize: theme.typography.pxToRem(26),
    fontWeight: theme.typography.fontWeightMedium
  }
}))

const OVERLAP_WIDTH = 80
const OPENEDS = []
const TRIGGER_OPEN = fn => {
  if (typeof fn === 'function') {
    OPENEDS.forEach((fn, index) => {
      fn(OPENEDS.length - index)
    })
    OPENEDS.push(fn)
    return () => {
      OPENEDS.pop()
      OPENEDS.forEach((fn, index) => {
        fn(OPENEDS.length - index - 1)
      })
    }
  }
}

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction='left' ref={ref} {...props} />
})

const SideModal = forwardRef(({ open, children, onClose, title, ...other }, ref) => {
  const [index, setIndex] = useState(0)
  const classes = useStyles()

  useEffect(() => {
    if (open) {
      return TRIGGER_OPEN(setIndex)
    }
  }, [open])

  return (
    <Dialog
      scroll='body'
      {...other}
      open={open}
      TransitionComponent={Transition}
      ref={ref}
      fullScreen
      className={classes.root}
      style={{ marginLeft: -index * OVERLAP_WIDTH }}
      onClose={onClose}
    >
      <DialogContent className={classes.content}>
        <div className={classes.header}>
          <IconButton onClick={onClose}>
            <BackIcon />
          </IconButton>
          <Typography className={classes.title}>{title}</Typography>
        </div>
        {children}
      </DialogContent>
    </Dialog>
  )
})

SideModal.propTypes = {
  children: PropTypes.node.isRequired,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool,
  title: PropTypes.string
}

export default SideModal
