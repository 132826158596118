import React from 'react'
import PropTypes from 'prop-types'

import { withStyles, styled } from '@material-ui/core/styles'
import { compose, borders, palette, display, flexbox, positions, shadows, sizing, spacing } from '@material-ui/system'
import MuiAccordion from '@material-ui/core/Accordion'
import MuiAccordionSummary from '@material-ui/core/AccordionSummary'
import MuiAccordionDetails from '@material-ui/core/AccordionDetails'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

import Text from '../Text/Text'

const ExpandableCard = ({ title, children, accordionSummaryProps, ...rest }) => (
  <StyledAccordion square {...rest}>
    <AccordionSummary {...accordionSummaryProps} expandIcon={<ExpandMoreIcon />}>
      <Text fontWeight={600}>{title}</Text>
    </AccordionSummary>
    <AccordionDetails>{children}</AccordionDetails>
  </StyledAccordion>
)

const Accordion = withStyles(theme => ({
  root: {
    padding: theme.spacing(3),
    boxShadow: 'none',
    borderBottom: `1px solid ${theme.palette.neutral.n200}`,
    '&:last-child': {
      borderBottom: 0
    },
    '&:before': {
      display: 'none'
    },
    '&$expanded': {
      margin: 0
    }
  },
  expanded: {}
}))(MuiAccordion)

const AccordionSummary = withStyles({
  root: {
    padding: 0,
    minHeight: 0,
    '&$expanded': {
      minHeight: 0
    }
  },
  content: {
    margin: 0,
    '&$expanded': {
      margin: '0 0 26px'
    }
  },
  expandIcon: {
    padding: 0,
    alignSelf: 'flex-start'
  },
  expanded: {}
})(MuiAccordionSummary)

const AccordionDetails = withStyles({
  root: {
    padding: 0
  }
})(MuiAccordionDetails)

const StyledAccordion = styled(Accordion)(
  compose(borders, palette, display, flexbox, positions, shadows, sizing, spacing)
)

ExpandableCard.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  accordionSummaryProps: PropTypes.object,
  ...StyledAccordion.propTypes
}

export default ExpandableCard
