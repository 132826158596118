import { styled } from '@material-ui/core/styles'
import {
  compose,
  palette,
  display,
  positions,
  shadows,
  sizing,
  spacing,
  typography,
  borders,
  flexbox
} from '@material-ui/system'

import Typography from '@material-ui/core/Typography'

const Text = styled(Typography)(
  compose(display, positions, shadows, sizing, spacing, typography, palette, borders, flexbox)
)

Text.defaultProps = {
  color: 'neutral.n500'
}

export default Text
