import React from 'react'
import PropTypes from 'prop-types'

import { Checkbox, FormControlLabel, FormHelperText } from '@material-ui/core'

import { Column } from '../'

const CheckBoxComponent = ({ name, label, checked, onChange, error, disabled, id, ...props }) => {
  const ref = id || name || 'checkbox'
  return (
    <Column {...props}>
      <FormControlLabel
        label={label}
        control={
          <Checkbox
            id={ref}
            name={name}
            checked={checked}
            onChange={() => onChange(!checked)}
            disabled={disabled}
            {...props}
          />
        }
        {...props}
      />
      {!!error && <FormHelperText>{error}</FormHelperText>}
    </Column>
  )
}

CheckBoxComponent.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  checked: PropTypes.bool,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  error: PropTypes.string
}

export default CheckBoxComponent
