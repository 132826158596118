import React from 'react'
import PropTypes from 'prop-types'

import { TableContainer, Table } from '@material-ui/core'
import { TableHead as MUITableHead, TableRow as MUITableRow, TableCell as MUITableCell } from '@material-ui/core'
import SortIcon from '@material-ui/icons/ImportExport'
import { styled } from '@material-ui/core/styles'
import {
  compose,
  borders,
  display,
  flexbox,
  positions,
  shadows,
  sizing,
  spacing,
  typography,
  palette
} from '@material-ui/system'

const SimplifiedTable = ({ children, component: Component = 'div' }) => (
  <TableContainer component={Component}>
    <Table>{children}</Table>
  </TableContainer>
)

export const TableHead = styled(MUITableHead)(
  compose(borders, display, flexbox, positions, shadows, sizing, spacing, typography, palette)
)
export const TableRow = styled(MUITableRow)(
  compose(borders, display, flexbox, positions, shadows, sizing, spacing, typography, palette)
)

export const TableCell = ({ sortable, children, ...props }) => (
  <StyledTableCell {...props}>
    {sortable && <SortIcon style={{ fontSize: 14, marginBottom: -2 }} />}
    {children}
  </StyledTableCell>
)

const StyledTableCell = styled(MUITableCell)(
  compose(borders, display, flexbox, positions, shadows, sizing, spacing, typography, palette)
)

SimplifiedTable.propTypes = {
  children: PropTypes.node.isRequired,
  component: PropTypes.node
}

export default SimplifiedTable
