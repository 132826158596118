const config = {
  toolbarGroups: [
    { name: 'styles', groups: ['styles'] },
    { name: 'document', groups: ['mode', 'document', 'doctools'] },
    { name: 'clipboard', groups: ['clipboard', 'undo'] },
    { name: 'editing', groups: ['find', 'selection', 'spellchecker', 'editing'] },
    { name: 'forms', groups: ['forms'] },
    { name: 'basicstyles', groups: ['basicstyles', 'cleanup'] },
    { name: 'colors', groups: ['colors'] },
    { name: 'paragraph', groups: ['list', 'align', 'indent', 'blocks', 'bidi', 'paragraph'] },
    { name: 'links', groups: ['links'] },
    { name: 'insert', groups: ['insert'] },
    { name: 'tools', groups: ['tools'] },
    { name: 'others', groups: ['others'] },
    { name: 'about', groups: ['about'] }
  ],
  toolbar: [
    [
      'Format',
      'Bold',
      'Italic',
      'Underline',
      'RemoveFormat',
      '-',
      'TextColor',
      '-',
      'Strike',
      'Subscript',
      'Superscript',
      '-',
      'BulletedList',
      'NumberedList',
      'JustifyLeft',
      'JustifyCenter',
      'JustifyRight',
      'JustifyBlock',
      'Outdent',
      'Indent',
      '-',
      'HorizontalRule',
      'Image',
      'Table',
      'ShowBlocks',
      '-',
      'Maximize'
    ]
  ],
  removeButtons:
    'Source,NewPage,Save,Templates,ExportPdf,Preview,Print,Cut,Copy,Paste,PasteText,PasteFromWord,Redo,Undo,Find,Replace,SelectAll,Scayt,Form,Checkbox,Radio,TextField,Textarea,Select,Button,ImageButton,HiddenField,CopyFormatting,Blockquote,CreateDiv,BidiLtr,BidiRtl,Language,Link,Unlink,Anchor,Flash,Smiley,SpecialChar,PageBreak,Iframe,Styles,Font,FontSize,BGColor,About',
  removePlugins: 'elementspath',
  resize_enabled: false,
  extraPlugins: 'justify,colorbutton'
}

export default config
