import React from 'react'
import PropTypes from 'prop-types'

import {
  Radio,
  RadioGroup as MaterialRadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
  FormHelperText
} from '@material-ui/core'

import { styled } from '@material-ui/core/styles'
import { compose, display, flexbox, shadows, sizing, spacing, borders, palette, typography } from '@material-ui/system'

const StyledRadioGroup = styled(MaterialRadioGroup)(compose(display, flexbox))
const StyledFormControl = styled(FormControl)(compose(display, flexbox, shadows, sizing, spacing, borders))

const RadioGroup = ({ name, label, value, color, disabled, error, onChange, options, id, labelProps, ...props }) => (
  <StyledFormControl component='fieldset' id={id || name || 'radio_group'} {...props}>
    <Label component='legend' fontFamily='Roboto' {...labelProps}>
      {label}
    </Label>
    <StyledRadioGroup name={name} value={String(value)} onChange={onChange} {...props}>
      {options.map((item, index) => (
        <FormControlLabel
          key={item.value}
          id={`${id || name || 'radio_group'}_${index}`}
          control={<Radio color={color} disabled={disabled} />}
          value={String(item.value)}
          label={item.label}
        />
      ))}
    </StyledRadioGroup>
    {!!error && <FormHelperText error>{error}</FormHelperText>}
  </StyledFormControl>
)

const Label = styled(FormLabel)(compose(palette, typography))

RadioGroup.defaultProps = {
  color: 'primary',
  border: 'none',
  disabled: false,
  labelProps: {}
}

RadioGroup.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
  color: PropTypes.oneOf(['primary', 'secondary']),
  border: PropTypes.string,
  disabled: PropTypes.bool,
  error: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]).isRequired
    })
  ).isRequired,
  labelProps: PropTypes.object
}

export default RadioGroup
