import { styled } from '@material-ui/core/styles'
import { compose, borders, palette, display, flexbox, positions, shadows, sizing, spacing } from '@material-ui/system'

import { Card } from '@material-ui/core'

const CardComponent = styled(Card)(compose(borders, palette, display, flexbox, positions, shadows, sizing, spacing))

CardComponent.defaultProps = {
  px: 1,
  py: 2
}

export default CardComponent
