import React from 'react'
import PropTypes from 'prop-types'
import { styled, makeStyles } from '@material-ui/core/styles'
import red from '@material-ui/core/colors/red'
import orange from '@material-ui/core/colors/orange'
import teal from '@material-ui/core/colors/teal'
import {
  compose,
  borders,
  display,
  flexbox,
  positions,
  shadows,
  sizing,
  spacing,
  typography
} from '@material-ui/system'

import { CircularProgress, Button } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  error: {
    color: props => (props.variant === 'contained' ? theme.palette.getContrastText(red[400]) : red[400]),
    backgroundColor: props => (props.variant === 'contained' ? red[400] : 'transparent'),
    '&:hover': {
      backgroundColor: props => (props.variant === 'contained' ? red[400] : red[50])
    }
  },
  warning: {
    color: props => (props.variant === 'contained' ? theme.palette.getContrastText(orange[700]) : orange[700]),
    backgroundColor: props => (props.variant === 'contained' ? orange[700] : 'transparent'),
    '&:hover': {
      backgroundColor: props => (props.variant === 'contained' ? orange[700] : orange[50])
    }
  },
  confirm: {
    color: props => (props.variant === 'contained' ? theme.palette.getContrastText(teal[500]) : teal[500]),
    backgroundColor: props => (props.variant === 'contained' ? teal[500] : 'transparent'),
    '&:hover': {
      backgroundColor: props => (props.variant === 'contained' ? teal[500] : teal[50])
    }
  }
}))

const ButtonComponent = ({ isLoading, disabled, loaderColor, children, ...props }) => {
  const classes = useStyles({ variant: props.variant })
  if (props.color === 'error') {
    props.color = 'default'
    props.className = classes.error
  } else if (props.color === 'warning') {
    props.color = 'default'
    props.className = classes.warning
  } else if (props.color === 'confirm') {
    props.color = 'default'
    props.className = classes.confirm
  }

  return (
    <StyledButton disabled={isLoading || disabled} {...props}>
      {isLoading && <Loader size={24} color={loaderColor} />}
      {children}
    </StyledButton>
  )
}

const StyledButton = styled(Button)(compose(borders, display, flexbox, positions, shadows, sizing, spacing, typography))

const Loader = styled(CircularProgress)({
  position: 'absolute'
})

ButtonComponent.defaultProps = {
  color: 'primary',
  loaderColor: 'primary'
}

ButtonComponent.propTypes = {
  variant: PropTypes.string,
  color: PropTypes.string,
  disabled: PropTypes.bool,
  isLoading: PropTypes.bool,
  startIcon: PropTypes.any,
  endIcon: PropTypes.any,
  loaderColor: PropTypes.string
}

export default ButtonComponent
