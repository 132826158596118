export const defaultTheme = {
  palette: {
    primary: {
      light: '#ffd36a',
      main: '#ffb400',
      dark: '#d69906'
    },
    secondary: {
      light: '#3cb9ae',
      main: '#009688',
      dark: '#197068'
    },
    neutral: {
      n100: '#fafafa',
      n150: '#f1f1f1',
      n200: '#dedede',
      n300: '#c1c1c1',
      n400: '#666666',
      n500: '#515151'
    }
  },
  spacing: [0, 8, 16, 24, 32, 40, 48, 56, 64, 72, 80]
}

export const lexTheme = {
  palette: {
    primary: {
      contrastText: '#fff',
      dark: '#004E8A',
      light: '#62A9ED',
      main: '#217ABA'
    },
    secondary: {
      contrastText: '#fff',
      dark: '#088D7F',
      light: '#86F1E0',
      main: '#51BEAE'
    },
    neutral: {
      n100: '#FAFAFA',
      n150: '#F1F1F1',
      n200: '#DEDEDE',
      n300: '#C1C1C1',
      n400: '#666666',
      n500: '#515151'
    },
    linearGradient: {
      main: 'linear-gradient(to right, #449ab2, #68bfac)'
    },
    success: {
      contrastText: '#111',
      dark: '#388e3c',
      light: '#81c784',
      main: '#4caf50'
    }
  },
  spacing: [0, 8, 16, 24, 32, 40, 48, 56, 64, 72, 80]
}
