import React from 'react'
import PropTypes from 'prop-types'
import Tooltip from '@material-ui/core/Tooltip'

import Row from '../Grid/Row'
import Column from '../Grid/Column'
import Text from '../Text/Text'

import Card from './Card'

const getPercentage = (value, max) => {
  return `${(value / max) * 100}%`
}

const CardWithProgress = ({ title, min, max, current = 0, ...props }) => {
  const tooltipTitle = !!max ? `Mínimo de ${min}, máximo de ${max}` : `Mínimo de ${min}`

  return (
    <Card px={3} py={3} {...props}>
      <Text fontSize={18} fontWeight={500} mb={2}>
        {title}
      </Text>
      <Row width='100%' justifyContent='flex-end'>
        <Text fontSize={12}>{!!max ? max : min}</Text>
      </Row>
      <Tooltip title={tooltipTitle} placement='top-start'>
        <Row width='100%' position='relative' height={32} bgcolor='neutral.n150'>
          <Row width={getPercentage(current, !!max ? max : min)} bgcolor='primary.main' height={32} />
          {!!max && (
            <Column position='absolute' left={getPercentage(min, max)}>
              <Column height={32} borderLeft='1px dashed #666666' />
            </Column>
          )}
        </Row>
      </Tooltip>
      <Text fontSize={12}>{current}</Text>
    </Card>
  )
}

CardWithProgress.propTypes = {
  title: PropTypes.string.isRequired,
  min: PropTypes.number.isRequired,
  max: PropTypes.number,
  current: PropTypes.number
}

export default CardWithProgress
