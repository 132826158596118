import React from 'react'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'
import { styled } from '@material-ui/core/styles'

import { Row, Text } from '../'

const BreadcrumbComponent = ({ paths, ...props }) => {
  const history = useHistory()

  const onClick = to => {
    if (typeof to === 'function') {
      return to()
    }
    history.push(to)
  }

  return (
    <Row {...props}>
      {paths.map(({ label, to }, index) => {
        const isTheLast = index === paths.length - 1

        return (
          <Path
            mr='5px'
            key={label}
            disabled={isTheLast}
            color='neutral.n500'
            id={`breadcrumb_${label}`}
            onClick={() => !isTheLast && onClick(to)}
          >
            {`${label} ${isTheLast ? '' : '/'}`}
          </Path>
        )
      })}
    </Row>
  )
}

const Path = styled(Text)({
  fontSize: 12,
  cursor: ({ disabled }) => (disabled ? '' : 'pointer'),
  '&:hover': {
    textDecoration: ({ disabled }) => (disabled ? '' : 'underline')
  }
})

BreadcrumbComponent.propTypes = {
  paths: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      to: PropTypes.oneOfType([PropTypes.string, PropTypes.func])
    })
  )
}

export default BreadcrumbComponent
