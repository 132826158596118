/* eslint-disable quotes */
import React from 'react'
import PropTypes from 'prop-types'

import { makeStyles } from '@material-ui/core/styles'
import clsx from 'clsx'

import Box from '@material-ui/core/Box'
import Checkbox from '@material-ui/core/Checkbox'
import MaterialTreeItem from '@material-ui/lab/TreeItem'

import Text from '../Text'

const useStyles = makeStyles(theme => ({
  label: {
    fontWeight: 'inherit',
    fontSize: '14px'
  },
  check: {
    padding: '4px',
    '&:hover': {
      backgroundColor: 'transparent'
    }
  },
  icon: {
    border: `solid 1px ${theme.palette.neutral.n400}`,
    width: 14,
    height: 14,
    backgroundColor: '#fff'
  },
  checked: {
    backgroundColor: '#009688',
    border: `solid 1px ${theme.palette.secondary.main}`,
    '&:before': {
      display: 'block',
      width: 14,
      height: 14,
      backgroundImage:
        "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='1 1 16 16'%3E%3Cpath" +
        " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
        "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
      content: '""'
    }
  }
}))

const TreeItem = ({ id, name, checked, children, onCheckClick, onLabelClick, onIconClick, ...rest }) => {
  const classes = useStyles()

  return (
    <MaterialTreeItem
      classes={{ label: classes.label }}
      key={id}
      nodeId={id}
      onLabelClick={onLabelClick}
      onIconClick={onIconClick}
      label={
        <Box display='flex' flexDirection='row'>
          <Checkbox
            checked={checked}
            disableRipple
            className={classes.check}
            onChange={onCheckClick}
            disableRipple
            color='default'
            checkedIcon={<span className={clsx(classes.icon, classes.checked)} />}
            icon={<span className={classes.icon} />}
          />
          <Text display='flex' alignItems='center' fontSize={14}>
            {name}
          </Text>
        </Box>
      }
      {...rest}
    >
      {children}
    </MaterialTreeItem>
  )
}

TreeItem.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  checked: PropTypes.bool,
  children: PropTypes.node,
  onCheckClick: PropTypes.func,
  onLabelClick: PropTypes.func
}

TreeItem.defaultProps = {
  checked: false,
  children: null,
  onCheckClick: () => null,
  onLabelClick: () => null
}

export default TreeItem
