import React from 'react'
import { createMuiTheme, ThemeProvider as MUITheme } from '@material-ui/core/styles'
import 'fontsource-roboto'

import { defaultTheme } from './Theme.js'

const ThemeProvider = ({ children, theme = {} }) => {
  const muiTheme = createMuiTheme({
    ...defaultTheme,
    ...theme
  })

  return <MUITheme theme={muiTheme}>{children}</MUITheme>
}

export { ThemeProvider }
